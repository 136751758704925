<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="downloadExcle">导出</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <vxe-form-item title="姓名" span="8">
          <vxe-input v-model="searchData.name" placeholder="请输入姓名" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="性别" span="8">
          <vxe-select v-model="searchData.sex" placeholder="请选择性别" style="width:100%;">
            <vxe-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value"></vxe-option>
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="年龄" span="8">
          <vxe-input style="width:50%;display:inline-block" v-model="searchData.ageBegin" placeholder="请输入年龄区间" auto-complete="off"></vxe-input>
          <vxe-input style="width:50%;display:inline-block" v-model="searchData.ageEnd" placeholder="请输入年龄区间" auto-complete="off"></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="身份证号" span="8">
          <vxe-input v-model="searchData.idNum" placeholder="请输入身份证号" auto-complete="off"></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="机构名称" span="8">
          <vxe-input v-model="searchData.orgName" placeholder="请输入机构名称" auto-complete="off"></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="户籍" span="8">
          <vxe-input v-model="searchData.address" placeholder="请输入户籍" auto-complete="off"></vxe-input>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:leaveTime="{ row }">
          <span>{{ $tools.reversalTime(row.leaveTime) }}</span>
        </template>
        <template v-slot:factTime="{ row }">
          <span>{{ $tools.reversalTime(row.factTime) }}</span>
        </template>
        <template v-slot:operation="{ row }">
          <vxe-button type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
          <vxe-button type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
        </template>
      </vxe-grid>
      <!-- 分页 -->
      <div class="page">
        <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
        ></vxe-pager>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      sexList: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
      loading: false,
      seachStatus: false,
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center',
        },
        {
          field: 'name',
          title: '姓名',
          showOverflow: true,
          align: 'center',
          minWidth: '200',
        },
        {
          field: 'sex',
          title: '性别',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'age',
          title: '年龄',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'idNum',
          title: '身份证号',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'address',
          title: '住址',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'bedNum',
          title: '床位号',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'orgName',
          title: '入住机构',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'enrtyOrgBeginTime',
          title: '入住时间',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'leaveOrg',
          title: '是否离院',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'leaveOrgTime',
          title: '离院时间',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId']),
  },
  watch: {
    seqId() {
      // this.getLists()
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['gitpersonnellinfo', 'delWorkPersonServe']),
    initView() {
      // this.getTeamList();
      // this.getCategoryList();
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    downloadExcle() {
      // 导出
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddWorkPerson',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id,
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData),
        },
      })
    },
    getLists() {
      this.loading = true
      this.gitpersonnellinfo({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: this.searchData,
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delWorkPersonServe({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success',
          })
          this.getLists()
        }
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
